import { css, type CSSObject, type SerializedStyles } from "@emotion/react";
import type { ReactNode } from "react";

import { cssFns } from "../css";
import { useScreenDetector } from "../screen-type";
import { usePrincipalColors, useSecondaryColors } from "../theme";
import type { SizeUnit } from "../utils";
import type { ButtonV2Props } from ".";

export type ButtonViewVariant = (
  | {
      variant?: "solid";
    }
  | {
      variant: "solid-icon";
      dropDownIconState?: boolean;
    }
  | {
      variant: "outlined";
    }
  | {
      variant: "outlined-blue";
    }
  | {
      variant: "outlined-icon";
      dropDownIconState?: boolean;
    }
  | {
      variant: "small";
    }
  | {
      variant: "small-outlined";
    }
  | {
      variant: "filter-search";
    }
  | {
      variant: "filter-outlined";
    }
  | {
      variant: "filter-outlined-icon";
      dropDownIconState?: boolean;
    }
  | {
      variant: "filter-reviews";
    }
  | {
      variant: "timeslot";
      height?: SizeUnit;
    }
  | {
      variant: "link-icon";
      dropDownIconState?: boolean;
    }
  | {
      variant: "link";
    }
  | {
      variant: "link-non-text-decorated";
    }
  | {
      variant: "link-gray";
    }
  | {
      variant: "link-gray-small";
    }
  | {
      variant: "icon";
      icon: ReactNode;
    }
  | {
      variant: "toggle-rate";
    }
  | {
      variant: "secondary-small";
    }
  | {
      variant: "selected-option";
    }
  | {
      variant: "accordion";
    }
) &
  (
    | {
        /**
         * Make button span all available width
         */
        full?: boolean;
      }
    | {
        width?: SizeUnit;
        minWidth?: SizeUnit;
      }
  );

export const useButtonV2Styles = (
  props: ButtonV2Props & {
    isHovered: boolean;
    isPressed: boolean;
    isIconVariant: boolean;
  },
) => {
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const { isMobile } = useScreenDetector();

  if (!props.variant) {
    props.variant = "solid";
  }

  const {
    variant,
    active,
    disabled,
    isHovered,
    isPressed,
    isIconVariant,
    icon,
  } = props;

  const waterfall = <T,>(
    normalStyle: T,
    hoverStyle?: T,
    disabledStyle?: T,
    onClickStyle?: T,
  ): T => {
    return (
      (disabled
        ? disabledStyle
        : isPressed || active
          ? onClickStyle
          : isHovered
            ? hoverStyle
            : normalStyle) ?? normalStyle
    );
  };

  const styles: Array<CSSObject | SerializedStyles> = [];

  switch (props.variant) {
    case "small":
    case "solid-icon":
    case "solid": {
      styles.push(
        cssFns.typo({ level: "body-1", weight: "semibold" }),
        variant === "small"
          ? cssFns.padding("6px")
          : cssFns.padding("10px", "14px"),
        cssFns.border({ radius: "4px", width: "0px" }),
        {
          backgroundColor: waterfall(
            principalColors.ebblue,
            "#97BFDA",
            "#D8DCDE",
          ),
          color: waterfall(
            principalColors.white,
            undefined,
            cssFns.setOpacity(principalColors.white, 0.7),
          ),
          height: variant === "small" ? "32px" : isMobile ? "46px" : "40px",
        },
      );
      break;
    }
    case "secondary-small": {
      styles.push(
        cssFns.typo({ level: "body-1", weight: "semibold" }),
        cssFns.padding("5px", "10px"),
        cssFns.border({ radius: "4px", width: "0px" }),
        {
          backgroundColor: waterfall(
            principalColors.grayline,
            undefined,
            "#D8DCDE",
          ),
          color: waterfall(
            principalColors.ebblue,
            cssFns.setOpacity(principalColors.ebblue, 0.5),
          ),
          height: 32,
        },
      );
      break;
    }
    case "outlined-icon":
    case "small-outlined":
    case "outlined-blue":
    case "outlined":
    case "selected-option":
    case "accordion": {
      const outlinedIconColor = waterfall(
        principalColors.ebblue,
        "#97BFDA",
        "#D8DCDE",
      );

      styles.push(
        cssFns.typo({
          level: variant === "accordion" ? "title-4" : "body-1",
          weight: variant === "selected-option" ? "regular" : "semibold",
        }),
        cssFns.padding("10px", variant === "accordion" ? 20 : "14px"),
        cssFns.border({
          radius: variant === "accordion" ? 8 : "4px",
          width: "1px",
          color:
            variant === "selected-option"
              ? secondaryColors.midgreen
              : variant === "outlined-icon"
                ? outlinedIconColor
                : waterfall(
                    principalColors.grayline,
                    principalColors.ebblue,
                    "#D8DCDE",
                  ),
          style: "solid",
        }),
        {
          backgroundColor:
            variant === "selected-option"
              ? cssFns.setOpacity(secondaryColors.midgreen, 0.1)
              : "transparent",
          color:
            variant === "selected-option" || variant === "accordion"
              ? principalColors.gs2
              : variant === "outlined-icon"
                ? outlinedIconColor
                : waterfall(
                    variant === "outlined-blue"
                      ? principalColors.ebblue
                      : principalColors.gs4,
                    principalColors.ebblue,
                    "#D8DCDE",
                  ),
          height:
            variant === "accordion"
              ? 52
              : variant === "small-outlined"
                ? "32px"
                : isMobile
                  ? "46px"
                  : "40px",
        },
      );
      break;
    }
    case "filter-search":
    case "filter-reviews":
    case "filter-outlined-icon":
    case "filter-outlined": {
      styles.push(
        cssFns.typo({ level: "body-1", weight: "regular" }),
        isMobile
          ? cssFns.padding("6px", "12px")
          : cssFns.padding("6px", "16px"),
        cssFns.border({
          radius: "36px",
          width: variant === "filter-search" ? "0px" : "1px",
          style: variant === "filter-search" ? "none" : "solid",
          color:
            variant === "filter-search"
              ? "transparent"
              : principalColors.grayline,
        }),
        {
          backgroundColor:
            variant === "filter-search"
              ? waterfall(
                  principalColors.ebblue,
                  undefined,
                  undefined,
                  secondaryColors.darkblue,
                )
              : waterfall(
                  "transparent",
                  undefined,
                  undefined,
                  variant === "filter-outlined-icon"
                    ? undefined
                    : principalColors.grayline,
                ),
          color:
            variant === "filter-reviews"
              ? principalColors.gs2
              : variant === "filter-search"
                ? principalColors.white
                : waterfall(
                    principalColors.gs4,
                    principalColors.gs2,
                    undefined,
                    variant === "filter-outlined-icon"
                      ? principalColors.gs2
                      : principalColors.gs4,
                  ),
          height: isMobile || variant === "filter-search" ? "32px" : "40px",
        },
      );
      break;
    }
    case "timeslot": {
      styles.push(
        isMobile
          ? cssFns.typo({ level: "body-1", weight: "semibold" })
          : cssFns.typo({ level: "body-2", weight: "semibold" }),
        cssFns.padding(0, 10),
        cssFns.border({ radius: "4px", width: "0px" }),
        {
          backgroundColor: waterfall(
            principalColors.ebblue,
            "#97BFDA",
            "#D8DCDE",
          ),
          color: waterfall(
            principalColors.white,
            undefined,
            cssFns.setOpacity(principalColors.white, 0.7),
          ),
          height: (props.height ?? isMobile) ? 32 : 24,
        },
      );
      break;
    }
    case "link-icon": {
      styles.push(
        cssFns.typo({ level: "body-1", weight: "semibold" }),
        cssFns.padding("10px", "14px"),
        cssFns.border({
          radius: "4px",
          width: "0px",
        }),
        {
          backgroundColor: "transparent",
          color: waterfall(principalColors.ebblue, "#97BFDA", "#D8DCDE"),
          height: isMobile ? "46px" : "40px",
        },
      );
      break;
    }
    case "link": {
      styles.push(
        cssFns.typo({ level: "body-1", weight: "regular" }),
        cssFns.padding("0px"),
        cssFns.border({ width: "0px" }),
        {
          color: principalColors.ebblue,
          backgroundColor: "transparent",
          textDecoration: waterfall("underline", "none"),
          width: "auto",
          height: "auto",
        },
      );
      break;
    }
    case "link-non-text-decorated": {
      styles.push(
        cssFns.typo({ level: "body-1", weight: "semibold" }),
        cssFns.padding("0px"),
        cssFns.border({ width: "0px" }),

        {
          textAlign: "right",
          display: "block",
          color: waterfall(principalColors.ebblue, "#97BFDA"),
          backgroundColor: "transparent",
          textDecoration: "none",
        },
      );
      break;
    }
    case "link-gray": {
      styles.push(
        cssFns.typo({ level: "body-1", weight: "regular" }),
        cssFns.padding("0px"),
        cssFns.border({ width: "0px" }),
        {
          color: principalColors.gs4,
          backgroundColor: "transparent",
          textDecoration: waterfall("underline", "none"),
          width: "auto",
          height: "auto",
        },
      );
      break;
    }
    case "link-gray-small": {
      styles.push(
        cssFns.typo({ level: "body-2", weight: "regular" }),
        cssFns.padding("0px"),
        cssFns.border({ width: "0px" }),
        {
          color: principalColors.gs4,
          backgroundColor: "transparent",
          textDecoration: waterfall("underline", "none"),
          width: "auto",
          height: "auto",
        },
      );
      break;
    }
    case "icon": {
      styles.push(
        cssFns.typo({ level: "body-1", weight: "semibold" }),
        cssFns.padding("0"),
        {
          background: "transparent",
          border: "none",
          color: waterfall(principalColors.gs2),
        },
      );
      break;
    }
    case "toggle-rate": {
      styles.push(
        isMobile
          ? cssFns.typo({ level: "body-1", weight: "semibold" })
          : cssFns.typo({ level: "body-2", weight: "semibold" }),
        isMobile ? cssFns.padding("9px") : cssFns.padding("8px"),
        cssFns.border({
          radius: "0",
          width: "0px",
        }),
        {
          backgroundColor: waterfall(
            cssFns.setOpacity(principalColors.ebblue, 0.14),
            "#97BFDA",
            "#D8DCDE",
            principalColors.ebblue,
          ),
          color: waterfall(
            principalColors.ebblue,
            cssFns.setOpacity(principalColors.white, 0.7),
            undefined,
            principalColors.white,
          ),
          height: isMobile ? "35px" : "40px",
          paddingBlockStart: isMobile ? undefined : 11,
        },
      );
      break;
    }
  }

  const full = "full" in props && props["full"] ? "100%" : undefined;
  const width = "width" in props ? props["width"] : undefined;
  const minWidth = "minWidth" in props ? props["minWidth"] : undefined;

  return css([
    {
      outline: "none",
      display: "flex",
      columnGap: "8px",
      alignItems: "center",
      justifyContent:
        isMobile || (!icon && !isIconVariant) ? "center" : "space-between",
      transition: "all 0.2s ease",
      whiteSpace: "nowrap",
      width: full ?? width ?? "auto",
      minWidth,
      "&:hover": {
        cursor: "pointer",
      },
    },
    ...styles,
  ]);
};
