import {
  BaseTextField,
  type BaseTextFieldProps,
  type TextFieldState,
} from "./text-field";

type EmailFieldState = TextFieldState;

export const EmailField = (props: BaseTextFieldProps<EmailFieldState>) => {
  return (
    <BaseTextField type="email" inputMode="email" name="email" {...props} />
  );
};
