import { Flags as BaseFlags } from "@hermes/flags";
import type { CountryId } from "@hermes/shared/schemas";
import { type ReactNode } from "react";

import { useAppConfig } from "./config/context";

export const Flags = ({ children }: { children: ReactNode }) => {
  const { country } = useAppConfig();

  const enableForCountries = (ids: CountryId[]) =>
    ids.includes(country.countryId);

  const joinClinics = {
    // Enable clinics join page
    "92dd44a7-5e13-447a-b440-3e7bf45058e5": true,
    // Enable join clinics button in home page clinics carousel
    "23d0518e-b0a1-4d08-85e4-14e5c6780aa3": true,
    // Enable clinics join link in mobile navbar
    "7ad9d25b-d6c2-45a6-81ef-6425708a3d05": true,
    // Enable connect clinics link in footer
    "182ff80f-e11a-47e7-80c8-ac036bf562c3": true,
  };

  const doctorsList = {
    // Enable doctors list page
    "ad2fe9e4-05a4-424b-9502-4d8d9aa7c6e5": true,
    // Enable children filter in doctors list page
    "1a7d4b25-6711-4f32-8118-ba3a5556d167": true,
    // Enable near filter in doctors list page
    "635e3e5e-50c6-47f6-b2d3-fdf24547c652": true,
    // Enable date filter in doctors list page
    "ff5b8251-73f3-43b0-91c6-205bd5d4b3ba": true,
    // Enable language filter in doctors list page
    "language-filter": enableForCountries(["hu"]),
    //Enable service doctor-list redirect page
    "27b2a7f0-90ba-4cf7-9db3-b54e772912e5": true,
  };

  const clinicsCabinetLink = {
    // Enable clinic cabinet link in mobile navbar
    "e63c1595-0fb8-4e8c-b9fa-d55cb40fee07": Boolean(
      import.meta.env.DOQ_CABINET_URL,
    ),
    // Enable clinic cabinet link in footer
    "56a4a446-39fe-4235-b8a3-8d9e758636db": Boolean(
      import.meta.env.DOQ_CABINET_URL,
    ),
  };

  const legals = {
    "appointment.consent": Boolean(
      import.meta.env.DOQ_ENABLE_APPOINTMENT_CONSENT,
    ),
    "legals.impresszum": Boolean(import.meta.env.DOQ_ENABLE_IMPRESSZUM),
    "legals.privacy_policy": Boolean(import.meta.env.DOQ_ENABLE_PRIVACY_POLICY),
    "legals.user_gtc": Boolean(import.meta.env.DOQ_ENABLE_USER_GTC),
    "legals.processing_agreement": Boolean(
      import.meta.env.DOQ_ENABLE_PROCESSING_AGREEMENT,
    ),
  };

  const appointmentModal = {
    "appointment-modal.enable-surname": Boolean(
      import.meta.env.DOQ_ENABLE_SURNAME,
    ),
  };

  return (
    <BaseFlags
      value={{
        ...joinClinics,
        ...doctorsList,
        ...clinicsCabinetLink,
        ...legals,
        ...appointmentModal,
        // Enable home page route
        "e87753e2-a203-433f-8897-55257c104f1d": true,
        // Enable doctor-details page route
        "e875ec8f-4de9-4179-ae86-7621dffc876b": true,
        // Enable clinics page route
        "f7de8dcf-2c10-4017-b8e2-5eab52c93c8d": true,
        // Enable clinic profile page route
        "93558975-dfc5-413e-b514-5c4b2f7f8bfa": true,
        // Enable appointment page
        "58c494f3-b6d1-4f64-bcab-6b213859fd94": true,
        // Enable procedure-list page
        "dba053e6-5061-42d2-bc8d-c0055891eb9d": true,
        //Enable procedures-list redirect page
        "33e9f146-f362-4a91-bccf-a734a23dfe39": true,
        // Enable procedures-by-category
        "f4f5c635-00da-556a-8ca6-ba47de27467b": true,
        // Enable feedback page
        "f27904f1-2832-45f8-b12c-0660dab35258": true,
        // Enable login page
        "922e045c-4644-47e4-addf-87810adf52ba": true,
        // Enable children filter in clinic page
        "d6e18446-cc14-4375-9660-0ff66dde2982": true,
        // Enable survey banner
        "dd290c38-c1c5-406b-aaab-ee8c0c71ec0e": true,
        // Enable cookie-bot
        "abcbff01-5a83-4652-8afd-2180a7c9f9e1": Boolean(
          import.meta.env.DOQ_COOKIE_BOT_ID,
        ),
        // Enable otp
        "b25a834b-b76d-4f54-9a10-29aecad190cd": Boolean(
          import.meta.env.DOQ_ENABLE_OTP,
        ),
      }}
    >
      {children}
    </BaseFlags>
  );
};
