import { useV1Services } from "@hermes/api";
import { Flag } from "@hermes/flags";
import { Message } from "@hermes/intl";
import { cssFns, TriggerButton, usePrincipalColors } from "@hermes/ui";
import { useState } from "react";
import { NavLink } from "react-router-dom";

import { useAppConfig } from "#internal/app/config/context";
import { useRoutePath } from "#internal/app/router";
import { GroupedSpecialties } from "#internal/features/grouped-specialties";
import { CitySelect } from "#internal/features/search-bar/city-select";
import { SpecialtiesListSkeleton } from "#internal/pages/home/specialties-list";
import accountCircleIcon from "#internal/shared/assets/images/account_circle_dark.svg";
import arrowDownIcon from "#internal/shared/assets/images/arrow_down_gray.svg";
import arrowUpIcon from "#internal/shared/assets/images/arrow_up_gray.svg";
import plusCircleIcon from "#internal/shared/assets/images/plus_circle_dark.svg";

export const SideMenuMainContent = ({
  handleCloseMenu,
}: {
  handleCloseMenu: () => void;
}) => {
  const { routePath } = useRoutePath();
  const [isSpecialtiesListOpen, setIsSpecialtiesOpen] = useState(false);
  const principalColors = usePrincipalColors();
  const { city, cityArea } = useAppConfig();
  const { data: servicesData } = useV1Services(
    {
      city: city.id,
      type: "initial-appointment",
      has_adult_service: false,
      is_primary: true,
      city_area: cityArea?.id,
    },
    { enabled: isSpecialtiesListOpen },
  );

  const commonStyles = [
    {
      display: "flex",
      alignItems: "center",
      gap: 12,
      color: "inherit",
      textDecoration: "none",
    },
    cssFns.typo({ level: "body-1", weight: "semibold" }),
  ];

  return (
    <div>
      <ul
        css={{
          display: "flex",
          flexDirection: "column",
          gap: 28,
          listStyle: "none",
          padding: 0,
        }}
      >
        <NavLink
          css={[
            cssFns.typo({ level: "body-1", weight: "semibold" }),
            {
              textDecoration: "none",
              color: principalColors.gs2,
            },
          ]}
          to={routePath({ path: "/" })}
          onClick={handleCloseMenu}
        >
          <Message id="common.home-page" />
        </NavLink>
        <li>
          <div
            onClick={() => {
              setIsSpecialtiesOpen(!isSpecialtiesListOpen);
            }}
            css={[
              cssFns.typo({ level: "body-1", weight: "semibold" }),
              {
                textDecoration: "none",
                color: principalColors.gs2,

                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 6,
              },
            ]}
          >
            <Message id="common.doctors" default="Врачи" />
            <div>
              <img src={isSpecialtiesListOpen ? arrowUpIcon : arrowDownIcon} />
            </div>
          </div>
          <div>
            {isSpecialtiesListOpen &&
              (servicesData ? (
                <GroupedSpecialties
                  onClick={handleCloseMenu}
                  services={servicesData.results}
                />
              ) : (
                <SpecialtiesListSkeleton />
              ))}
          </div>
        </li>
        <NavLink
          css={[
            cssFns.typo({ level: "body-1", weight: "semibold" }),
            {
              textDecoration: "none",
              color: principalColors.gs2,
            },
          ]}
          onClick={handleCloseMenu}
          to={routePath({
            path: "/clinics/:city",
            params: { city: city.slug },
          })}
        >
          <Message id="common.clinics" default="Клиники" />
        </NavLink>
        <NavLink
          css={[
            cssFns.typo({ level: "body-1", weight: "semibold" }),
            {
              textDecoration: "none",
              color: principalColors.gs2,
            },
          ]}
          onClick={handleCloseMenu}
          to={routePath({
            path: "/procedures/:city",
            params: {
              city: city.slug,
            },
          })}
        >
          <Message id="common.services.procedures" default="Процедуры" />
        </NavLink>

        <CitySelect>
          {(triggerRef, triggerProps) => (
            <TriggerButton ref={triggerRef} triggerProps={triggerProps}>
              <div
                css={{
                  display: "flex",
                  gap: 4,
                  alignItems: "baseline",
                }}
              >
                <span
                  css={[
                    {
                      color: principalColors.gs8,
                    },
                    cssFns.typo({ level: "body-1", weight: "regular" }),
                  ]}
                >
                  <Message id="common.city" default="Город:" />
                </span>
                <span
                  css={[
                    {
                      color: principalColors.gs2,
                    },
                    cssFns.typo({ level: "body-1", weight: "semibold" }),
                  ]}
                >
                  {`${cityArea ? cityArea.name + ", " : ""} ${city.name.toString()}`}
                </span>
              </div>
            </TriggerButton>
          )}
        </CitySelect>
      </ul>
      <ul
        css={[
          cssFns.borderBlockEnd({
            width: 1,
            style: "solid",
            color: principalColors.grayline,
          }),
          cssFns.borderBlockStart({
            width: 1,
            style: "solid",
            color: principalColors.grayline,
          }),
          cssFns.paddingBlock(30),
          cssFns.paddingInline(0),
          cssFns.marginBlock(30),
          {
            display: "flex",
            flexDirection: "column",
            gap: 24,
          },
        ]}
      >
        <Flag
          id="442d2b5f-67df-4146-94da-42c64ed7934a"
          description="Enable patient login link"
        >
          <a css={commonStyles}>
            <img src={accountCircleIcon} alt="account_icon" />
            <span>
              <Message
                id="abdb18ec-cd4d-49c4-bf8f-531a90394cba"
                default="Вход для пациента"
              />
            </span>
          </a>
        </Flag>

        <Flag
          id="e63c1595-0fb8-4e8c-b9fa-d55cb40fee07"
          description="Enable clinic cabinet link"
        >
          <a href={import.meta.env.DOQ_CABINET_URL} css={commonStyles}>
            <img src={plusCircleIcon} alt="plus_icon" />
            <span>
              <Message
                id="cfdb78f3-8765-4ac3-b954-f84542fbb335"
                default="Вход для клиники"
              />
            </span>
          </a>
        </Flag>
      </ul>
      <ul
        css={{
          display: "flex",
          flexDirection: "column",
          gap: 28,
          padding: 0,
          a: [
            cssFns.typo({ level: "body-1", weight: "semibold" }),
            {
              textDecoration: "none",
              color: principalColors.gs2,
            },
          ],
        }}
      >
        <Flag
          id="7ad9d25b-d6c2-45a6-81ef-6425708a3d05"
          description="Enable clinics join link in mobile navbar"
        >
          <NavLink to={routePath({ path: "/join" })} onClick={handleCloseMenu}>
            <Message
              id="common.link.join-clinic"
              default="Подключить клинику"
            />
          </NavLink>
        </Flag>

        <NavLink
          to={routePath({
            path: "/contacts",
          })}
          onClick={handleCloseMenu}
        >
          <Message id="contacts.main-title" default="Наши контакты" />
        </NavLink>
      </ul>
    </div>
  );
};
