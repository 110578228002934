import { Message, useMessage } from "@hermes/intl";
import { cssFns, usePrincipalColors } from "@hermes/ui";
import { Link } from "react-router-dom";

import { useRoutePath } from "#internal/app/router";

export const AppointmentLegalsBlock = () => {
  const { routePath } = useRoutePath();
  const message = useMessage();
  const principalColors = usePrincipalColors();

  return (
    <div
      css={[
        cssFns.typo({ level: "body-3", weight: "regular" }),
        { maxWidth: 540, color: principalColors.gs4 },
      ]}
    >
      <Message
        id="87e710ae-fece-4c3c-8970-f8b26117ef98"
        default="Нажимая Записаться, я принимаю {userAgreement}, {confidentiality} и даю свое {processing}."
        values={{
          userAgreement: (
            <Link
              key="user-agreement"
              to={routePath({ path: "/user_gtc" })}
              css={{
                textDecoration: "none",
                color: principalColors.ebblue,
              }}
              target="_blank"
            >
              {message({
                id: "3991697f-855e-4b7e-bd65-d1c6a3c44b76",
                default: "условия пользовательского соглашения",
              })}
            </Link>
          ),
          confidentiality: (
            <Link
              key="confidentiality"
              to={routePath({ path: "/privacy_policy" })}
              css={{
                textDecoration: "none",
                color: principalColors.ebblue,
              }}
              target="_blank"
            >
              {message({
                id: "9db8a9d9-8949-4d96-8f6a-87cb843727a6",
                default: "положения о защите персональных данных",
              })}
            </Link>
          ),
          processing: (
            <Link
              key="processing"
              to={routePath({ path: "/processing-agreement" })}
              css={{
                textDecoration: "none",
                color: principalColors.ebblue,
              }}
              target="_blank"
            >
              {message({
                id: "7d1df16c-8769-46ef-8080-ce9b4219a5d5",
                default: "согласие на обработку персональных данных",
              })}
            </Link>
          ),
        }}
      />
    </div>
  );
};
