import { css } from "@emotion/react";
import { Flag } from "@hermes/flags";
import { Message } from "@hermes/intl";
import {
  cssFns,
  LayoutContainer,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";
import { NavLink } from "react-router-dom";

import { useAppConfig } from "#internal/app/config/context";
import { useRoutePath } from "#internal/app/router";
import surveyBannerBgMobile from "#internal/shared/assets/images/survey_banner_bg.svg";
import surveyBannerBgDesktop from "#internal/shared/assets/images/survey_banner_bg_desktop.svg";

export const SurveyBanner = () => {
  const { routePath } = useRoutePath();
  const principalColors = usePrincipalColors();
  const { isMobile } = useScreenDetector();
  const { country } = useAppConfig();

  const surveyBannerBg = isMobile
    ? surveyBannerBgMobile
    : surveyBannerBgDesktop;

  return (
    <Flag
      id="dd290c38-c1c5-406b-aaab-ee8c0c71ec0e"
      description="Enable survey banner"
    >
      <LayoutContainer
        styles={css([
          {
            borderBottom: isMobile ? `1px solid ${principalColors.white}` : 0,
            ...(isMobile && { background: principalColors.white }),
          },
        ])}
      >
        <section
          css={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "31px 10px 30px",
            backgroundColor: principalColors.ebblue,
            borderRadius: isMobile ? "12px 12px 0 0" : 12,
            marginBlockEnd: isMobile ? 0 : 20,
            maxWidth: isMobile ? "100vw" : "",
            textAlign: isMobile ? "left" : "center",
            backgroundImage: `url("${surveyBannerBg.toString()}")`,
          }}
        >
          <div>
            <h2
              css={[
                cssFns.typo({
                  level: "title-3",
                  weight: "semibold",
                }),
                {
                  color: principalColors.white,
                },
              ]}
            >
              <Message
                id="ef0b723e-4159-4349-89cb-6063f1df384e"
                default="Подскажите нам"
              />
            </h2>
            <p
              css={[
                cssFns.typo({
                  level: "body-1",
                  weight: "regular",
                }),
                {
                  color: principalColors.white,
                },
              ]}
            >
              <Message
                id="91bd1625-6426-4ba2-9913-76dc497c40d5"
                default="Как мы можем сделать {brand} лучше для вас?"
                values={{
                  brand: country.brandName,
                }}
              />
            </p>
          </div>
          <NavLink
            css={[
              cssFns.typo({
                level: "body-1",
                weight: "semibold",
              }),
              {
                display: "block",
                padding: "14px 0",
                width: isMobile ? 220 : 230,
                border: `1px solid ${principalColors.white}`,
                textDecoration: "none",
                textAlign: "center",
                borderRadius: 4,
                marginTop: 48,
                cursor: "pointer",
                backgroundColor: "transparent",
                color: principalColors.white,
                "@media(min-width:1024px)": {
                  backgroundColor: principalColors.white,
                  color: principalColors.ebblue,
                  margin: "30px auto 0",
                },
                "&:hover": {
                  background: "rgba(255,255,255,0.2)",
                  color: principalColors.white,
                },
              },
            ]}
            rel="nofollow"
            to={routePath({ path: "/survey" })}
            target="_blank"
          >
            <Message
              id="04c9bb34-fc0c-4591-8b53-4fb1c001d924"
              default="Пройти опрос"
            />
          </NavLink>
        </section>
      </LayoutContainer>
    </Flag>
  );
};
