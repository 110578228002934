import { Message } from "@hermes/intl";
import { cssFns, usePrincipalColors, useScreenDetector } from "@hermes/ui";
import { type RefObject, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { useAppConfig } from "#internal/app/config/context";
import { GroupedSpecialties } from "#internal/features/grouped-specialties";

import { useHomePagePrefetchData } from "../data";

export const SpecialtiesList = ({
  containerRef,
  onFetched,
}: {
  containerRef?: RefObject<HTMLDivElement>;
  onFetched?: () => void;
}) => {
  const principalColors = usePrincipalColors();
  const { city, cityArea } = useAppConfig();
  const { isMobile } = useScreenDetector();

  const {
    data: servicesData,
    isLoading: isServicesDataLoading,
    isFetched,
  } = useHomePagePrefetchData({
    city: city.id,
    cityArea: cityArea?.id,
  });

  useEffect(() => {
    if (isFetched) onFetched?.();
  }, [isFetched]);

  return (
    <section
      css={[
        cssFns.padding(isMobile ? "30px" : "20px", isMobile ? "16px" : "20px"),
        {
          backgroundColor: principalColors.white,
          borderRadius: 12,
        },
      ]}
      ref={containerRef}
    >
      <h2
        css={[
          cssFns.typo({
            level: "title-3",
            weight: "semibold",
          }),
          {
            display: "flex",
            justifyContent: "space-between",
            margin: 0,
            paddingBottom: "8px",
            borderBottom: `1px solid ${principalColors.grayline}`,
          },
        ]}
      >
        <div>
          <Message id="common.doctors" />
        </div>
        <div
          css={cssFns.typo({
            level: "title-3",
            weight: "regular",
          })}
        >
          &nbsp;{city.doctors_count}
        </div>
      </h2>
      {!isServicesDataLoading && servicesData ? (
        <GroupedSpecialties services={servicesData.results} />
      ) : (
        <SpecialtiesListSkeleton />
      )}
    </section>
  );
};

export const SpecialtiesListSkeleton = () => {
  const principalColors = usePrincipalColors();
  const { isMobile } = useScreenDetector();
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      {Array.from({ length: 5 }, (_, index) => (
        <div
          css={[
            cssFns.padding("20px", "0"),
            {
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 6,

              borderTop: `1px solid ${principalColors.grayline}`,
            },
          ]}
          key={index}
        >
          <Skeleton
            css={[
              cssFns.typo({ level: "body-1", weight: "semibold" }),
              {
                width: 20,
              },
            ]}
          />
          <div
            css={{
              display: "flex",
              flexWrap: "wrap",
              gap: 30,

              width: "100%",
            }}
          >
            {Array.from({ length: 8 }, (_, index) => (
              <Skeleton
                key={index}
                css={[
                  {
                    height: 28,
                    width: isMobile ? "40vw" : 210,
                  },
                ]}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
