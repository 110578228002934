import { type City, type CityArea, useV1CityAreas } from "@hermes/api";
import { useMessage } from "@hermes/intl";
import {
  cssFns,
  Select,
  type SelectImperativeRef,
  TextField,
  type TriggerChildrenProps,
  useFormField,
  usePreviousState,
  usePrincipalColors,
  useTextFieldState,
} from "@hermes/ui";
import { type ReactNode, type RefObject, useEffect, useState } from "react";
import {
  Navigate,
  type Params,
  useLocation,
  useMatches,
  useParams as useRouteParams,
} from "react-router-dom";

import { useAppConfig } from "#internal/app/config/context";
import {
  cityAreasRoutes,
  cityRoutes,
  RoutesIdEnum,
  useRoutePath,
} from "#internal/app/router";
import { MobileArrowLeft } from "#internal/shared/ui/icons";

const useCityRoutePath = () => {
  const { routePath } = useRoutePath();

  return {
    cityRoutePath({
      routeId,
      citySlug,
      cityAreaSlug,
      params,
    }: {
      citySlug: string;
      cityAreaSlug?: string;
      params: Readonly<Params<string>>;
      routeId: RoutesIdEnum;
    }) {
      switch (routeId) {
        case RoutesIdEnum.DOCTORS_LIST:
          return routePath({
            path: "/doctors/:city/:service",
            params: {
              city: citySlug,
              service: params["*"]!,
            },
            query: {
              city_area: cityAreaSlug,
            },
          });
        case RoutesIdEnum.CLINICS_LIST:
          return routePath({
            path: "/clinics/:city",
            params: {
              city: citySlug,
            },
          });
        case RoutesIdEnum.PROCEDURES_LIST:
          return routePath({
            path: "/procedures/:city",
            params: {
              city: citySlug,
            },
          });
        case RoutesIdEnum.CLINIC_PROFILE:
          return routePath({
            path: "/clinics/:city/:slug",
            params: {
              city: citySlug,
              slug: params["*"]!,
            },
          });
        default:
          return routePath({ path: "/404" });
      }
    },
  };
};

export const useCityRouteInfo = () => {
  const params = useRouteParams();
  const location = useLocation();

  const routeCitySlug = params["city"];
  const routeCityAreaSlug = new URLSearchParams(location.search).get(
    "city_area",
  );

  return {
    routeCitySlug,
    routeCityAreaSlug,
  };
};

export const useResolveCityRouteInfo = ({
  cities,
  cityAreas,
}: {
  cities: City[];
  cityAreas?: CityArea[];
}) => {
  const { routeCitySlug, routeCityAreaSlug } = useCityRouteInfo();
  const routeCity = cities.find((c) => c.slug === routeCitySlug);
  const routeCityArea = cityAreas?.find((a) => a.slug === routeCityAreaSlug);

  return {
    routeCitySlug,
    routeCity,
    routeCityAreaSlug,
    routeCityArea,
  };
};

export const CitySyncWrapper = ({ children }: { children: ReactNode }) => {
  const params = useRouteParams();
  const location = useLocation();
  const { city, cities, cityArea, cityAreas, areCityAreasFetched } =
    useAppConfig();
  const { cityRoutePath } = useCityRoutePath();
  const { routePath } = useRoutePath();

  const matches = useMatches();
  const outletRoute = matches[1];

  const routeId = outletRoute?.id as unknown as RoutesIdEnum;
  const hasCity = cityRoutes.includes(routeId);

  const previousCitySlug = usePreviousState(city.slug);
  const { routeCity, routeCityArea, routeCityAreaSlug } =
    useResolveCityRouteInfo({
      cities,
      cityAreas,
    });

  // В маршрутах у которых нет city параметра в url
  if (!hasCity) {
    // Нужно переводить пользователя на главную страницу если поменять город через интерфейс
    if (previousCitySlug !== city.slug && location.pathname !== "/") {
      return <Navigate to={routePath({ path: "/" })} replace />;
    }
    return children;
  }

  // Если город из url не был найден
  if (!routeCity) {
    return (
      <Navigate
        to={cityRoutePath({
          citySlug: city.slug,
          params,
          routeId,
        })}
        replace
      />
    );
  }

  // Если город в url не совпадает с тем что указано в app config
  if (routeCity.slug !== city.slug) {
    // Переводим пользователя на страницу с нужным городом
    return (
      <Navigate
        to={cityRoutePath({
          citySlug: city.slug,
          params,
          routeId,
          cityAreaSlug: cityArea?.slug ?? undefined,
        })}
      />
    );
  }

  const hasCityArea = cityAreasRoutes.includes(routeId);
  if (!hasCityArea || !routeCityAreaSlug || !areCityAreasFetched)
    return children;

  // Если район из url не был найден
  if (!routeCityArea) {
    return (
      <Navigate
        to={cityRoutePath({
          citySlug: city.slug,
          params,
          routeId,
        })}
        replace
      />
    );
  }

  // Если район в url не совпадает с тем что указано в app config
  if (routeCityArea.slug !== cityArea?.slug) {
    // Переводим пользователя на страницу с нужным районом
    return (
      <Navigate
        to={cityRoutePath({
          citySlug: city.slug,
          params,
          routeId,
          cityAreaSlug: cityArea?.slug,
        })}
      />
    );
  }

  return children;
};

export const CitySelect = ({
  cityRef,
  children,
}: {
  cityRef?: RefObject<SelectImperativeRef>;
} & TriggerChildrenProps) => {
  const { city, cityArea, cities, changeCity } = useAppConfig();

  const message = useMessage();
  const principalColors = usePrincipalColors();

  const [areasCache, setAreasCache] = useState(new Map<number, CityArea[]>());
  const [submenuCityId, setSubmenuCityId] = useState<number | undefined>(
    cityArea ? city.id : undefined,
  );

  useEffect(() => {
    if (cityArea && !submenuCityId) {
      setSubmenuCityId(city.id);
    }
  }, [cityArea, submenuCityId, city.id]);

  const submenuCity = cities.find((city) => city.id === submenuCityId);
  const { data: cityAreasData, isFetching } = useV1CityAreas(
    { city: submenuCityId! },
    {
      enabled: !!submenuCityId,
    },
  );

  useEffect(() => {
    if (submenuCityId && cityAreasData) {
      setAreasCache((cache) => {
        cache.set(submenuCityId, cityAreasData.results);
        return new Map(cache);
      });
    }
  }, [cityAreasData?.results, submenuCityId]);

  const mobileFilterField = useFormField(useTextFieldState());
  const mobileSubmenuFilterField = useFormField(useTextFieldState());

  return (
    <Select<{ city: City; cityArea?: CityArea }>
      imperativeRef={cityRef}
      field={{
        value: {
          city,
          cityArea,
        },
        onChange(value) {
          if ("value" in value) {
            if (value.value?.city) {
              changeCity(value.value.city.slug, value.value.cityArea?.slug);
            }
          }
        },
      }}
      showParent
      mobile={{
        title: (
          <div css={[{ width: "100%" }, cssFns.padding(14)]}>
            <TextField
              field={mobileFilterField}
              placeholder={city.name}
              clearable
            />
          </div>
        ),
        submenuTitle: ({ close }) => (
          <div css={[{ width: "100%" }, cssFns.padding(14)]}>
            <TextField
              icon={
                <MobileArrowLeft
                  color={principalColors.ebblue}
                  width={24}
                  height={24}
                />
              }
              onIconPress={() => {
                close();
              }}
              field={mobileSubmenuFilterField}
              placeholder={
                submenuCity
                  ? message({
                      id: "e9b65e09-82ab-489a-afd4-29325d7be94f",
                      default: "Район в {city}",
                      values: {
                        city: submenuCity.name,
                      },
                    })
                  : undefined
              }
              clearable
            />
          </div>
        ),
      }}
      options={cities
        .filter((city) => {
          if (mobileFilterField.value) {
            return city.name.includes(mobileFilterField.value);
          }
          return true;
        })
        .map((city) => {
          const areas = areasCache.get(city.id);
          const options = [
            {
              label: message({
                id: "009beb03-6b61-4c21-a456-245d3a5919b1",
                default: "Все районы",
              }),
              value: { city },
            },
            ...(areas?.map((area) => ({
              label: area.name,
              value: {
                city,
                cityArea: area,
              },
            })) ?? []),
          ];

          return {
            label: city.name,
            value: { city },
            hasSubOptions: true,
            subOptions: options.filter((area) => {
              if (mobileSubmenuFilterField.value) {
                return area.label.includes(mobileSubmenuFilterField.value);
              }
              return true;
            }),
            areSubOptionsLoading: isFetching,
          };
        })}
      toKeyString={({ city, cityArea }) =>
        `${city.id}${cityArea?.id ? `-${cityArea.id}` : ""}`
      }
      onSubmenuOpen={(key) => {
        setSubmenuCityId(Number(key));
      }}
      onSubmenuClose={() => {
        setSubmenuCityId(undefined);
      }}
      children={children}
    />
  );
};
