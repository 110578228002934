import { type Service } from "@hermes/api";
import { cssFns, usePrincipalColors, useScreenDetector } from "@hermes/ui";
import { Link, useLocation } from "react-router-dom";

import { useAppConfig } from "#internal/app/config/context";
import { useRoutePath } from "#internal/app/router";

type GroupedData = {
  [key: string]: Service[];
};

export const GroupedServices = ({
  services,
  onClick,
  minimizedStyle = false,
}: {
  services: Service[];
  onClick?: () => void;
  minimizedStyle?: boolean;
}) => {
  const { routePath } = useRoutePath();
  const principalColors = usePrincipalColors();

  const { pathname } = useLocation();

  const { isMobile } = useScreenDetector();
  const { city } = useAppConfig();

  const groupedData: GroupedData = sortByName(services).reduce(
    (result: GroupedData, currentValue) => {
      const firstLetter: string = currentValue.name
        .trim()
        .charAt(0)
        .toUpperCase();
      result[firstLetter] = result[firstLetter] || [];
      result[firstLetter].push(currentValue);
      return result;
    },
    {},
  );
  return (
    <div
      css={
        !minimizedStyle
          ? {
              display: "flex",
              flexDirection: "column",
            }
          : {
              maxHeight: 800,
              columnCount: 5,
              breakInside: "avoid",
              lineBreak: "auto",
            }
      }
    >
      {Object.entries(groupedData).map(([key, specialties], index) => (
        <div
          css={[
            {
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? 6 : 20,
              borderTop: minimizedStyle
                ? "none"
                : `1px solid ${principalColors.grayline}`,
              ":first-of-type": {
                borderTop: "none",
              },
              alignItems: "baseline",
              paddingBlockStart: minimizedStyle ? 0 : 20,
              paddingBlockEnd: isMobile ? 11 : 20,
              breakInside: "avoid",
            },
          ]}
          key={index}
        >
          <div css={cssFns.typo({ level: "body-1", weight: "semibold" })}>
            {key}
          </div>
          <div
            css={
              minimizedStyle
                ? {
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                  }
                : isMobile
                  ? {
                      columnGap: "normal",
                      columnCount: 2,
                      columnFill: "balance",
                      breakInside: "avoid",
                      width: "100%",
                    }
                  : {
                      display: "grid",
                      gridTemplateColumns: `repeat(4, 1fr)`,
                      gap: 20,
                      width: "100%",
                    }
            }
          >
            {specialties.map((service, idx) => (
              <Link
                key={idx}
                to={routePath({
                  path: "/doctors/:city/:service",
                  params: {
                    city: city.slug,
                    service: service.slug,
                  },
                })}
                onClick={onClick}
                css={[
                  cssFns.typo({ level: "body-1", weight: "regular" }),
                  cssFns.padding(isMobile ? "5px" : "0", "0"),
                  {
                    textDecoration: "none",
                    width: "100%",
                    minWidth: isMobile ? "50%" : "25%",
                    display: isMobile ? "flex" : "flow",
                    color: principalColors.ebblue,
                    marginBlockEnd: isMobile ? 5 : 0,
                    ...(pathname.split("/")[3] === service.slug && {
                      color: cssFns.setOpacity(principalColors.ebblue, 0.5),
                    }),
                    ":hover": {
                      color: cssFns.setOpacity(principalColors.ebblue, 0.5),
                    },
                  },
                ]}
              >
                {service.name}
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const sortByName = (array: Service[]) =>
  array.sort((a, b) => a.name.localeCompare(b.name));
