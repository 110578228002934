import { css, type SerializedStyles } from "@emotion/react";
import {
  Message,
  useFormatCurrency,
  useFormatNumber,
  useMessage,
} from "@hermes/intl";
import {
  cssFns,
  Modal,
  useModalState,
  usePrincipalColors,
  useScreenDetector,
  useSecondaryColors,
} from "@hermes/ui";

import { useAppConfig } from "#internal/app/config/context";

export const DoctorServicePrice = ({
  styles,
  discount,
  price,
  total,
  info,
  service_type = "initial-appointment",
  insurances,
}: {
  styles?: SerializedStyles;
  price: number | null;
  discount: number | null;
  total: number | null;
  info?: string;
  service_type?: "initial-appointment" | "procedure";
  insurances?: number[];
}) => {
  total = total || 0;
  price = price || 0;
  discount = discount || 0;

  const { country, insurances: insurancesList } = useAppConfig();
  const message = useMessage();
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const { isMobile } = useScreenDetector();

  const hasDiscount = price !== total && discount > 0;
  const hasPrice = total > 0;

  const modalState = useModalState({});
  const insurancesMap = new Map(
    insurancesList.map((item) => [item.id, item.name]),
  );

  return (
    <>
      <div css={[styles]}>
        <div
          css={[
            cssFns.padding(5, 10),
            {
              paddingInlineStart: 0,
              display: "flex",
              gap: 6,
              alignItems: "center",
              borderRadius: 40,
              background: info
                ? cssFns.linearGradient("90deg", [
                    principalColors.white,
                    principalColors.grayline,
                  ])
                : undefined,
            },
          ]}
        >
          {hasDiscount && price && (
            <div
              css={[
                cssFns.typo({ level: "body-1", weight: "semibold" }),
                {
                  color: principalColors.gs4,
                  textDecoration: "line-through",
                },
              ]}
            >
              {formatNumber(price, {
                maximumFractionDigits: 0,
              })}
            </div>
          )}
          <div
            css={[
              cssFns.typo({ level: "body-1", weight: "semibold" }),
              { color: secondaryColors.magenta, margin: 0 },
            ]}
          >
            {hasPrice
              ? service_type === "procedure"
                ? message({
                    id: "a8212885-e010-465c-8046-a43a88d995d4",
                    default: "от {minPrice}",
                    values: {
                      minPrice: formatCurrency(total, country.currency, {
                        maximumFractionDigits: 0,
                      }),
                    },
                  })
                : formatCurrency(total, country.currency, {
                    maximumFractionDigits: 0,
                  })
              : message({
                  id: "5ca5462b-2e1e-4ea0-8c08-77ef5673c2ac",
                  default: "цена по запросу",
                })}
          </div>
          {hasDiscount && (
            <div
              css={[
                cssFns.padding(0, 5),
                cssFns.typo({ level: "body-2", weight: "regular" }),
                {
                  backgroundColor: secondaryColors.yellow,
                  color: principalColors.gs2,
                  borderRadius: 30,
                  height: 18,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  whiteSpace: "nowrap",
                },
              ]}
            >
              {formatNumber(-(discount / 100), {
                style: "percent",
              }).replace(/\s+/g, "")}{" "}
              {country.brandName}
            </div>
          )}
          <div
            itemProp="makesOffer"
            itemScope
            itemType="http://schema.org/Offer"
          >
            <meta itemProp="price" content={`${total}`} />
            <meta itemProp="priceCurrency" content={`${country.currency}`} />
          </div>
          {info && (
            <>
              <div css={{ flexGrow: 1 }} />
              <div
                css={[
                  cssFns.typo({ level: "body-1", weight: "semibold" }),
                  {
                    cursor: "pointer",
                    color: principalColors.ebblue,
                  },
                ]}
                onClick={modalState.open}
              >
                <Message
                  id="ad293577-fb9d-4d20-b45b-cd11e9d8097b"
                  default="подробнее"
                />
              </div>
            </>
          )}
        </div>
        {insurances && insurances.length > 0 && (
          <div
            css={[
              cssFns.typo({
                level: "body-1",
                weight: "semibold",
                color: secondaryColors.magenta,
              }),
              {
                marginBlockStart: 4,
              },
            ]}
          >
            {insurances.map((item) => insurancesMap.get(item)).join(", ")}
          </div>
        )}
      </div>
      {info && modalState.isOpen && (
        <Modal
          state={modalState}
          main={
            <div
              css={[
                isMobile ? cssFns.padding(18) : cssFns.padding(30),
                cssFns.typo({ level: "body-1", weight: "regular" }),
                { color: principalColors.gs2 },
              ]}
              dangerouslySetInnerHTML={{ __html: info }}
            ></div>
          }
          onClose={modalState.close}
          title={message({
            id: "67628dd9-8ed2-4c65-af30-94221bc76f2b",
            default: "Информация о стоимости",
          })}
          size="sm"
          styles={css({
            height: "auto",
          })}
        />
      )}
    </>
  );
};
