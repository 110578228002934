import { type City, type CityArea, type Insurance } from "@hermes/api";
import { type BaseCountryInfo } from "@hermes/shared/schemas";
import { createContext, useContext } from "react";

export type CountryInfo = BaseCountryInfo & {
  name: string;
  link: string;
  region: string;
  lang: string;
};

export type SearchLabel = {
  text: string;
  isLoading?: boolean;
};

type Config = {
  isHeadless?: boolean;
  isPrerender?: boolean;
  country: CountryInfo;
  city: City;
  cities: City[];
  availableCountriesInfo: CountryInfo[];
  cityArea?: CityArea;
  cityAreas?: CityArea[];
  areCityAreasFetched: boolean;
  countryPhone: {
    code: string;
    cityCodeLength: number;
    mask: string;
  };
  changeCity: (slug: string, areaSlug?: string | null) => void;
  changeCityArea: (slug: string | null) => void;
  searchLabel?: SearchLabel;
  changeSearchLabel: (label?: SearchLabel) => void;
  socialMediaLinks?: {
    facebook?: string;
    instagram?: string;
  };
  insurances: Insurance[];
};

export const Context = createContext<Config | undefined>(undefined);

export const useAppConfig = () => {
  const config = useContext(Context);

  if (!config) {
    throw new Error("useAppConfig requires AppConfigContext in the react tree");
  }

  return config;
};
