import { css } from "@emotion/react";
import {
  HermesAppContainer,
  makeDefaultAxiosInstance,
  makeDefaultQueryClient,
} from "@hermes/shared";
import {
  cssFns,
  Spinner,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";
import { type ReactNode, Suspense, useEffect } from "react";
import {
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
  useMatches,
  useNavigation,
  useParams,
} from "react-router-dom";

import { Footer } from "#internal/features/footer";
import { Header } from "#internal/features/header";
import { SubHeader } from "#internal/features/header/sub-header";
import { CitySyncWrapper } from "#internal/features/search-bar/city-select";
// import { AppHelmet } from "#internal/helmet";
import { messageCache } from "#internal/intl";
import { BtnScrollTop } from "#internal/shared/ui/btn-scroll-top";
import { countryInfo, useStoredLocale } from "#internal/shared/utils";

import { AppConfigContext } from "./config";
import { Flags } from "./flags";
import { GTagWrapper } from "./gtag";
import { useRoutePath } from "./router";

const ContentWrapper = ({ children }: { children: ReactNode }) => {
  const { isDesktop } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const matches = useMatches();
  const { state: navigationState } = useNavigation();
  const outletRoute = matches[1];

  const isHomeRoute = outletRoute?.id === "home";

  const headerHeight = isDesktop ? "80px" : "50px";

  const loadingFallback = (
    <div
      css={[
        cssFns.center(),
        {
          flexGrow: 1,
          minHeight: `calc(100vh - ${headerHeight})`,
          paddingBlockEnd: headerHeight,
        },
      ]}
    >
      <Spinner />
    </div>
  );

  return (
    <div
      css={{
        color: principalColors.gs2,
        backgroundColor: principalColors.gs10,
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* <AppHelmet /> */}
      <Header
        variant={isHomeRoute && isDesktop ? "transparent" : undefined}
        height={headerHeight}
      />
      {isDesktop && !isHomeRoute && <SubHeader />}
      <div css={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Suspense fallback={loadingFallback}>
          <CitySyncWrapper>{children}</CitySyncWrapper>
          {navigationState === "loading" && loadingFallback}
        </Suspense>
      </div>
      <Footer />
      <BtnScrollTop />
    </div>
  );
};

const queryClient = makeDefaultQueryClient();
export const axiosInstance = makeDefaultAxiosInstance({
  baseURL: import.meta.env.DOQ_DEBUG_API_URL || import.meta.env.DOQ_API_URL,
});
const SCROLL_POSITIONS_KEY = "scroll-positions";

export const AppContainer = () => {
  useEffect(() => {
    // Reset scroll positions on page refresh
    sessionStorage.removeItem(SCROLL_POSITIONS_KEY);
  }, []);

  return (
    <HermesAppContainer
      axiosInstance={axiosInstance}
      queryClient={queryClient}
      countryId={import.meta.env.DOQ_COUNTRY_ID}
      cookieDomain={
        Boolean(import.meta.env.DOQ_LOCAL_COOKIE)
          ? "localhost"
          : import.meta.env.DOQ_COOKIE_DOMAIN
      }
      autoSendToken={false}
      messageCache={messageCache}
      globalStyles={css([
        {
          "html,body": {
            maxWidth: "100vw",
            overflowX: "hidden",
            position: "relative",
          },
          "#root .react-loading-skeleton": {
            lineHeight: "24px",
          },
        },
      ])}
      storedLocaleKey="locale"
      polyFillExclude={["plural-rules"]}
    >
      <ScrollRestoration storageKey={SCROLL_POSITIONS_KEY} />
      <AppConfigContext>
        <Flags>
          <ContentWrapper>
            <GTagWrapper>
              <LangHandler>
                <Outlet />
              </LangHandler>
            </GTagWrapper>
          </ContentWrapper>
        </Flags>
      </AppConfigContext>
    </HermesAppContainer>
  );
};

const LangHandler = ({ children }: { children: ReactNode }) => {
  const { routePath } = useRoutePath();
  const params = useParams();
  const location = useLocation();
  const routeLocale = params["lang"];
  const [storedLocale, setStoredLocale] = useStoredLocale();

  const exists =
    routeLocale && (countryInfo.locales as string[]).includes(routeLocale);
  const condition = exists && routeLocale !== storedLocale;

  // Sync store locale with route locale once
  useEffect(() => {
    if (condition) {
      setStoredLocale(routeLocale);
    }
  }, []);

  // handle 404 for unknown route locale
  if (routeLocale && !exists && !location.pathname.includes("404")) {
    return <Navigate to={routePath({ path: "/404" })} replace />;
  }

  return children;
};
