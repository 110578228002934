import type { DoctorsListOrder } from "@hermes/api";
import { Flag } from "@hermes/flags";
import { Temporal, useFormatDateTime, useMessage } from "@hermes/intl";
import {
  ButtonV2,
  cssFns,
  DatePicker,
  fieldState,
  Select,
  type SelectImperativeRef,
  type SelectOption,
  truthy,
  useFormField,
  usePrincipalColors,
  useScreenDetector,
  useSelectState,
} from "@hermes/ui";
import { type SVGProps, useRef } from "react";

import { useAppConfig } from "#internal/app/config/context";
import { LineCloseIcon } from "#internal/shared/ui/icons";

type Params = {
  sort?: DoctorsListOrder;
  nearme?: boolean;
  date?: Temporal.PlainDate;
  language?: string;
  insurance?: number;
};

const FiltersMobileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <rect width="30" height="30" rx="15" fill="#3683B6" />
      <path
        d="M8.5 12L21.5 12"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50005 18L21.5 18"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="18" cy="12" r="2" fill="#3683B6" stroke="white" />
      <circle cx="12" cy="18" r="2" fill="#3683B6" stroke="white" />
    </svg>
  );
};

const FiltersMobileIconOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#E7F0F6" />
    <path
      d="M8.5 12L21.5 12"
      stroke="#B2B9BE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 18L21.4999 18"
      stroke="#B2B9BE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="18" cy="12" r="2" fill="white" stroke="#B2B9BE" />
    <circle cx="12" cy="18" r="2" fill="white" stroke="#B2B9BE" />
  </svg>
);

const Sort = ({
  params,
  onParamsChange,
  variant,
}: {
  params: Params;
  onParamsChange: (params: Params) => void;
  variant?: "filter-search" | "filter-outlined";
}) => {
  const message = useMessage();
  const { isMobile, isDesktop } = useScreenDetector();

  const options = [
    {
      value: "default",
      label: message({
        id: "726e1cde-dd98-4290-b4b6-91d57e3d03e2",
        default: "По умолчанию",
      }),
    },
    {
      value: "rating",
      label: message({
        id: "b76fa912-3ad1-4457-9029-b77db3eb7f85",
        default: "Рейтингу",
      }),
    },
    {
      value: "price",
      label: message({
        id: "e3b14f62-8955-4d56-a436-ae2fcccbfda1",
        default: "Цене",
      }),
    },
    {
      value: "experience",
      label: message({
        id: "31554e02-7099-4fae-947c-46cca5595ea4",
        default: "Стажу",
      }),
    },
    isMobile && {
      value: "distance",
      label: message({
        id: "doctors-list.filters.near",
      }),
    },
  ].filter(truthy) as SelectOption<DoctorsListOrder>[];

  const selectRef = useRef<SelectImperativeRef>(null);
  const field = useFormField(
    useSelectState<DoctorsListOrder>({
      value: params.sort || "default",
      parent: params.sort,
    }),
  );
  const value = (isDesktop ? params.sort : field.value) || "default";
  const selected =
    options.find((option) => option.value === value) || options[0];

  const handleChange = (value?: DoctorsListOrder) => {
    selectRef.current?.close();
    onParamsChange({
      sort: value,
      nearme: value === "distance",
    });
  };

  return (
    <Select
      imperativeRef={selectRef}
      closeOnSelect={isDesktop}
      field={{
        value: field.value,
        onChange(state) {
          if (isDesktop) {
            handleChange(fieldState(field, state).value ?? undefined);
          }
          field.onChange(state);
        },
      }}
      options={options}
      mobile={{
        title: message({
          id: "18e53d11-6a9f-4677-8aaf-87e8a63afb1a",
          default: "Сортировать по",
        }),
        height: "auto",
        footer: (
          <div
            css={[
              cssFns.padding(8),
              {
                display: "grid",
                width: "100%",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              },
            ]}
          >
            <ButtonV2
              text={message({
                id: "d93e3da6-e6f3-473a-b0fa-8ad9ba9628df",
                default: "Отменить",
              })}
              variant="outlined"
              onPress={() => {
                selectRef.current?.close();
              }}
            />
            <ButtonV2
              text={message({
                id: "9db59481-22a6-4d7c-806f-ae8f4ff52a34",
                default: "Применить",
              })}
              variant="solid"
              onPress={() => {
                handleChange(field.value ?? undefined);
              }}
            />
          </div>
        ),
      }}
    >
      {(triggerRef, triggerProps) =>
        isMobile ? (
          <ButtonV2
            triggerRef={triggerRef}
            triggerProps={triggerProps}
            variant="icon"
            icon={
              variant === "filter-outlined" ? (
                <FiltersMobileIconOutlined />
              ) : (
                <FiltersMobileIcon />
              )
            }
            text="sort-mobile-button"
          />
        ) : (
          <ButtonV2
            triggerRef={triggerRef}
            triggerProps={triggerProps}
            text={selected?.label || ""}
            variant="filter-outlined-icon"
            active={!!value}
            minWidth="165px"
          />
        )
      }
    </Select>
  );
};

export const DoctorListFilters = ({
  params,
  onParamsChange,
  enableChildFilter,
  isChild,
  onChildChange,
  variant,
}: {
  params: Params;
  onParamsChange: (params: Params) => void;
  enableChildFilter?: boolean;
  isChild?: boolean;
  onChildChange?: () => void;
  variant?: "filter-search" | "filter-outlined";
}) => {
  const principalColors = usePrincipalColors();
  const { isMobile } = useScreenDetector();
  const message = useMessage();
  const formatDateTime = useFormatDateTime();
  const { insurances } = useAppConfig();

  const languageOptions = [
    {
      label: "Chinese",
      value: { language_code: "zh-CN", id: 27, title: "Chinese" },
    },
    {
      label: "English",
      value: { language_code: "en", id: 1, title: "English" },
    },
    {
      label: "Hungarian",
      value: {
        language_code: "hu",
        id: 30,
        title: "Hungarian",
      },
    },
  ];

  const insurancesOptions = insurances.map((insurance) => ({
    label: insurance.name,
    value: insurance,
  }));

  return (
    <div
      css={[
        isMobile && [
          cssFns.padding(10),
          {
            backgroundColor: principalColors.ebblue,
            paddingInlineEnd: 0,
          },
        ],
        {
          display: "flex",
          alignItems: "center",
          columnGap: isMobile ? "6px" : "16px",
          overflowX: "auto",
          scrollbarWidth: "none",
        },
      ]}
    >
      <Sort params={params} onParamsChange={onParamsChange} variant={variant} />
      {enableChildFilter && (
        <Flag
          id="1a7d4b25-6711-4f32-8118-ba3a5556d167"
          description="Enable children filter in doctors list page"
        >
          <ButtonV2
            text={message({
              id: "doctors-list.filters.children",
              default: "Детский",
            })}
            variant={
              variant ? variant : isMobile ? "filter-search" : "filter-outlined"
            }
            active={isChild}
            id="isForChildren"
            onPress={onChildChange}
          />
        </Flag>
      )}
      <Flag
        id="635e3e5e-50c6-47f6-b2d3-fdf24547c652"
        description="Enable near filter in doctors list page"
      >
        <ButtonV2
          text={message({
            id: "doctors-list.filters.near",
            default: "Рядом",
          })}
          variant={
            variant ? variant : isMobile ? "filter-search" : "filter-outlined"
          }
          active={params.nearme}
          onPress={() => {
            onParamsChange({
              nearme: !params.nearme,
              sort: undefined,
            });
          }}
        />
      </Flag>
      <Flag
        id="ff5b8251-73f3-43b0-91c6-205bd5d4b3ba"
        description="Enable date filter in doctors list page"
      >
        <DatePicker
          value={params.date}
          onChange={(value) => {
            onParamsChange({ date: value });
          }}
          disabled={{
            before: new Date(),
          }}
        >
          {(triggerRef, triggerProps) => (
            <ButtonV2
              text={
                params.date
                  ? formatDateTime(params.date, {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })
                  : message({
                      id: "common.fields.appointment-date",
                      default: "Дата приема",
                    })
              }
              variant={
                variant
                  ? variant
                  : isMobile
                    ? "filter-search"
                    : "filter-outlined"
              }
              triggerRef={triggerRef}
              triggerProps={triggerProps}
              active={!!params.date}
              icon={
                params.date ? (
                  <LineCloseIcon
                    width={isMobile ? "20" : "24"}
                    height={isMobile ? "20" : "24"}
                    color={
                      isMobile ? principalColors.white : principalColors.gs8
                    }
                  />
                ) : undefined
              }
              onIconPress={
                params.date
                  ? () => {
                      onParamsChange({ date: undefined });
                    }
                  : undefined
              }
            />
          )}
        </DatePicker>
      </Flag>
      <Flag
        id="language-filter"
        description="Enable language filter in doctors list page"
      >
        <Select
          field={{
            value: languageOptions.find(
              ({ value }) => value.language_code === params.language,
            )?.value,
            onChange(state) {
              if ("value" in state && state.value) {
                onParamsChange({
                  ...params,
                  language: state.value.language_code,
                });
              }
            },
          }}
          fontWeight="regular"
          mobile={{
            title: message({
              id: "c8176565-1299-4e34-b908-1eae7192580e",
              default: "Язык",
            }),
          }}
          children={(triggerRef, triggerProps) => (
            <ButtonV2
              text={
                languageOptions.find(
                  ({ value }) => value.language_code === params.language,
                )?.value.title ??
                message({
                  id: "c8176565-1299-4e34-b908-1eae7192580e",
                })
              }
              variant={
                variant
                  ? variant
                  : isMobile
                    ? "filter-search"
                    : "filter-outlined"
              }
              active={!!params.language}
              triggerRef={triggerRef}
              triggerProps={triggerProps}
              icon={
                params.language ? (
                  <LineCloseIcon
                    width={isMobile ? "20" : "24"}
                    height={isMobile ? "20" : "24"}
                    color={
                      isMobile ? principalColors.white : principalColors.gs8
                    }
                  />
                ) : undefined
              }
              onIconPress={
                params.language
                  ? () => {
                      onParamsChange({ language: undefined });
                    }
                  : undefined
              }
              {...(!isMobile && { minWidth: "125px" })}
            />
          )}
          options={languageOptions}
        />
      </Flag>
      {insurances.length > 0 && (
        <Select
          field={{
            value: insurancesOptions.find(
              ({ value }) => value.id === params.insurance,
            )?.value,
            onChange(value) {
              if ("value" in value && value.value) {
                onParamsChange({
                  ...params,
                  insurance: value.value.id,
                });
              }
            },
          }}
          fontWeight="regular"
          mobile={{
            title: message({
              id: "1b37bfea-a8ef-4af1-a951-2e761223461c",
              default: "Insurance",
            }),
          }}
          children={(triggerRef, triggerProps) => (
            <ButtonV2
              text={
                insurancesOptions.find(
                  ({ value }) => value.id === params.insurance,
                )?.value.name ??
                message({ id: "1b37bfea-a8ef-4af1-a951-2e761223461c" })
              }
              variant={
                variant
                  ? variant
                  : isMobile
                    ? "filter-search"
                    : "filter-outlined"
              }
              active={!!params.insurance}
              triggerRef={triggerRef}
              triggerProps={triggerProps}
              icon={
                params.insurance ? (
                  <LineCloseIcon
                    width={isMobile ? "20" : "24"}
                    height={isMobile ? "20" : "24"}
                    color={
                      isMobile ? principalColors.white : principalColors.gs8
                    }
                  />
                ) : undefined
              }
              onIconPress={
                params.insurance
                  ? () => {
                      onParamsChange({ insurance: undefined });
                    }
                  : undefined
              }
              {...(!isMobile && { minWidth: "140px" })}
            />
          )}
          options={insurancesOptions}
        />
      )}
    </div>
  );
};
