import { useMessage } from "@hermes/intl";
import { capitalize } from "@hermes/shared";
import {
  cssFns,
  Select,
  TriggerButton,
  useFormField,
  usePrincipalColors,
  useSelectState,
} from "@hermes/ui";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { type CountryInfo, useAppConfig } from "#internal/app/config/context";

import { countryInfo, useStoredLocale } from "../utils";
import { LanguageIcon } from "./icons";

export const LanguageButton = () => {
  const principalColors = usePrincipalColors();

  const { country } = useAppConfig();
  const message = useMessage();
  const navigate = useNavigate();
  const location = useLocation();

  const [storedLocale, setStoredLocale] = useStoredLocale();

  const getLocaleOptions = (country: CountryInfo) => {
    const localesMap = {
      en: message({
        id: "362c39c1-603d-49f3-b56b-05fad01cd2ef",
        default: "English (EN)",
      }),
      ru: message({
        id: "1b51c072-fc48-460f-83a8-7d90be7d84f0",
        default: "Русский (RU)",
      }),
      kk: message({
        id: "29bf8507-3abf-4620-85b5-1ab70fa9ea42",
        default: "Қазақша (KZ)",
      }),
      hu: message({
        id: "637ea9c4-d73c-406b-9671-03995bf8e9ce",
        default: "Magyar (HU)",
      }),
      ro: message({
        id: "01a3b4ce-7c64-4f70-8fc8-eaa6af731895",
        default: "Română (RO)",
      }),
    };

    return country.locales.map((locale) => ({
      label: localesMap[locale] || locale,
      value: locale,
    }));
  };

  const options = useMemo(() => getLocaleOptions(country), [country, message]);

  const defaultLocale =
    options.find((option) => option.value === storedLocale) || options[0]!;

  const language = useFormField(
    useSelectState({
      value: defaultLocale.value,
      parent: storedLocale,
    }),
  );

  const content = (
    <div
      css={[
        cssFns.typo({ level: "body-1", weight: "semibold" }),
        {
          display: "flex",
          alignItems: "center",
          gap: 6,
          color: principalColors.white,
          userSelect: "none",
        },
      ]}
    >
      <LanguageIcon width={22} height={22} />
      {language.value ? capitalize(language.value) : language.value}
    </div>
  );

  return options.length > 1 ? (
    <div
      css={{
        display: "flex",
        gap: 6,
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Select
        field={language}
        options={options}
        showArrowDown={false}
        mobile={{
          title: message({
            id: "b50a7243-9b07-4b8d-beac-025cc2e169e6",
            default: "Выберите язык",
          }),
          height: "400px",
        }}
        desktop={{
          width: "auto",
        }}
        onSelectionChange={(option) => {
          setStoredLocale(option.value);

          // Without lang param
          const cleanPath = location.pathname.replace("/" + storedLocale, "");
          const prefix =
            option.value !== countryInfo.defaultLocale
              ? "/" + option.value
              : "";
          const to = `${prefix}${cleanPath}${location.search}`;

          if (to !== `${location.pathname}${location.search}`) {
            navigate(to, { replace: true });
          }
        }}
      >
        {(triggerRef, triggerProps) => (
          <TriggerButton ref={triggerRef} triggerProps={triggerProps}>
            {content}
          </TriggerButton>
        )}
      </Select>
    </div>
  ) : (
    content
  );
};
